@use "../library/breakpoints"
@use "include-media/dist/include-media"
@use "../library/fonts"

.weatherWidget
    position: relative
    align-self: flex-end
    color: #555
    bottom: 5px
    padding: 0 15px
    font-family: fonts.$fontRubrik
    font-size: 1rem
    line-height: 1

.weatherWidget--mobile
    display: block
    background-color: #fff
    padding: 53px 15px 0 15px
    min-height: 82px

.weatherWidget a
    text-decoration: none
    color: #555
    display: flex
    align-items: center
    min-width: 145px
    justify-content: space-between

.weatherWidget__city
    font-size: 0.75em
    flex-grow: 1

.weatherWidget__weatherIcon
    margin-right: 5px

    img
        width: 26px
        height: 26px

.weatherWidget__temperature
    font-size: 1.125em
    font-weight: normal

@include include-media.media(">=min")
    .weatherWidget
        bottom: 20px
        padding: 0

    .weatherWidget__city
        flex-grow: 0

    .weatherWidget__weatherIcon
        margin: initial

        img
            width: 30px
            height: 30px

    .weatherWidget__temperature
        font-size: 1.5em

    .weatherWidget--mobile
        display: none
